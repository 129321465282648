import * as React from 'react'
import { graphql, PageProps } from 'gatsby'

import Layout from 'components/layout/layout'
import SEO from 'components/shared/SEO'

import Navigation from 'components/layout/Navigation'
import PrivacyPolicy from 'components/layout/PrivacyPolicy'

const PrivacyPolicyPage: React.FC<
  PageProps<Queries.PrivacyPolicyPageQuery>
> = ({ data }) => {
  const PAGE_DATA = data?.wpPage?.PrivacyPolicy?.privacyPolicy
  const PAGE_SEO = data?.wpPage?.seo

  if (!PAGE_DATA || !PAGE_SEO) throw new Error("CMS data didn't load properly")

  return (
    <Layout>
      <SEO
        title={PAGE_SEO.title ?? ''}
        description={PAGE_SEO.metaDesc ?? ''}
        ogTitle={PAGE_SEO.opengraphTitle}
        ogDescription={PAGE_SEO.opengraphDescription}
        ogImage={PAGE_SEO.opengraphImage?.sourceUrl}
        twitterTitle={PAGE_SEO.twitterTitle}
        twitterDescription={PAGE_SEO.twitterDescription}
        twitterImage={PAGE_SEO.twitterImage?.sourceUrl}
      />
      <Navigation />
      <PrivacyPolicy privacyPolicyContent={PAGE_DATA!} />
    </Layout>
  )
}

export default PrivacyPolicyPage

export const query = graphql`
  query PrivacyPolicyPage {
    wpPage(slug: { regex: "/polityka-prywatnosci/" }) {
      seo {
        title
        metaDesc
        opengraphTitle
        opengraphDescription
        opengraphImage {
          sourceUrl
        }
        twitterTitle
        twitterDescription
        twitterImage {
          sourceUrl
        }
      }
      PrivacyPolicy {
        privacyPolicy
      }
    }
  }
`
