import React from 'react'
import styled from 'styled-components'

import Container from 'components/shared/container'

type Props = {
  privacyPolicyContent: string
}

const Wrapper = styled.div`
  margin-top: ${({ theme }) => theme.navSize.mobile};
  padding-top: 4rem;

  ${({ theme }) => theme.media.lg.min} {
    margin-top: ${({ theme }) => theme.navSize.desktop};
    padding-top: 6rem;
  }
`

const Content = styled.main`
  font-size: 18px;
  line-height: 1.3;
`

const PrivacyPolicy: React.FC<Props> = ({ privacyPolicyContent }) => {
  return (
    <Wrapper>
      <Container>
        <Content dangerouslySetInnerHTML={{ __html: privacyPolicyContent }} />
      </Container>
    </Wrapper>
  )
}

export default PrivacyPolicy
